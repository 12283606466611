import React, { useEffect, useRef } from 'react';
import { config } from '../code/vars';

interface VideoStreamProps {
  expertId: number;
  idleVideoUrl: string;
  onStreamIdAvailable?: (streamId: string, sessionId: string) => void;
}

const VideoStream: React.FC<VideoStreamProps> = ({ expertId, idleVideoUrl, onStreamIdAvailable }) => {
  const peerConnection = useRef<RTCPeerConnection | null>(null);
  const pcDataChannel = useRef<RTCDataChannel | null>(null);
  const streamId = useRef<string | null>(null);
  const sessionId = useRef<string | null>(null);
  const statsIntervalId = useRef<NodeJS.Timeout | null>(null);
  const lastBytesReceived = useRef<number>(0);
  const videoIsPlaying = useRef<boolean>(false);

  const idleVideoRef = useRef<HTMLVideoElement>(null);
  const streamVideoRef = useRef<HTMLVideoElement>(null);

  const stream_warmup = true;
  const isStreamReady = useRef<typeof stream_warmup>(true);

  const onIceCandidate = async (event: RTCPeerConnectionIceEvent) => {
    if (!streamId.current || !sessionId.current) return;

    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
      
      await fetch(config.api_base + `/video/${expertId}/stream/ice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          candidate,
          sdpMid,
          sdpMLineIndex,
          session_id: sessionId.current,
          stream_id: streamId.current
        }),
      });
    }
  };

  const onTrack = (event: RTCTrackEvent) => {
    if (!event.track) return;

    statsIntervalId.current = setInterval(async () => {
      if (!peerConnection.current) return;
      
      const stats = await peerConnection.current.getStats(event.track);
      stats.forEach((report) => {
        if (report.type === 'inbound-rtp' && report.kind === 'video') {
          const videoStatusChanged = videoIsPlaying.current !== report.bytesReceived > lastBytesReceived.current;

          if (videoStatusChanged) {
            videoIsPlaying.current = report.bytesReceived > lastBytesReceived.current;
            onVideoStatusChange(videoIsPlaying.current, event.streams[0]);
          }
          lastBytesReceived.current = report.bytesReceived;
        }
      });
    }, 500);
  };

  const onVideoStatusChange = (isPlaying: boolean, stream: MediaStream | null) => {
    if (!streamVideoRef.current || !idleVideoRef.current) return;

    if (isPlaying && stream) {
      streamVideoRef.current.srcObject = stream;
      streamVideoRef.current.style.opacity = isStreamReady.current ? '1' : '0';
      idleVideoRef.current.style.opacity = '0';
    } else {
      streamVideoRef.current.style.opacity = '0';
      idleVideoRef.current.style.opacity = '1';
    }
  };

  const onConnectionStateChange = () => {
    if (peerConnection.current?.connectionState === 'connected') {
      playIdleVideo();
      
      setTimeout(() => {
        if (!isStreamReady.current) {
          console.log('forcing stream/ready');
          isStreamReady.current = true;
          if (streamVideoRef.current && idleVideoRef.current) {
            streamVideoRef.current.style.opacity = '1';
            idleVideoRef.current.style.opacity = '0';
          }
        }
      }, 5000);
    }
  };

  const playIdleVideo = () => {
    if (idleVideoRef.current) {
      idleVideoRef.current.src = idleVideoUrl;
      idleVideoRef.current.play();
    }
  };

  const createPeerConnection = async (offer: RTCSessionDescriptionInit, iceServers: RTCIceServer[]) => {
    if (!peerConnection.current) {
      peerConnection.current = new RTCPeerConnection({ iceServers });
      pcDataChannel.current = peerConnection.current.createDataChannel('JanusDataChannel');
      
      peerConnection.current.addEventListener('icecandidate', onIceCandidate);
      peerConnection.current.addEventListener('track', onTrack);
      peerConnection.current.addEventListener('connectionstatechange', onConnectionStateChange);
    }

    await peerConnection.current.setRemoteDescription(offer);
    const answer = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(answer);

    return answer;
  };

  const initializeStream = async () => {
    if((window as any).streamIdStarted){
        return;
    }

    if (streamId.current || peerConnection.current) {
      console.log('Stream already initialized, skipping...');
      return;
    }

    (window as any).streamIdStarted = true;

    try {
      const streamResponse = await fetch(config.api_base + `/video/${expertId}/stream`, {
        method: 'POST'
      });
      const { id, offer, ice_servers, session_id } = await streamResponse.json();
      
      streamId.current = id;
      sessionId.current = session_id;

      console.log(streamId.current, id);

      if (onStreamIdAvailable && id && session_id) {
        onStreamIdAvailable(id, session_id);
      }

      const sessionClientAnswer = await createPeerConnection(offer, ice_servers);

      await fetch(config.api_base + `/video/${expertId}/stream/sdp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answer: sessionClientAnswer,
          session_id: session_id,
          stream_id: streamId.current
        }),
      });

    } catch (err) {
      console.error('Failed to initialize stream:', err);
    }
  };

  useEffect(() => {
    initializeStream();

    return () => {
      if (statsIntervalId.current) {
        clearInterval(statsIntervalId.current);
      }
      
      if (peerConnection.current) {
        peerConnection.current.close();
      }
    };
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <video 
        ref={idleVideoRef}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        playsInline
        autoPlay
        loop
      />
      <video
        ref={streamVideoRef}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: 0
        }}
        playsInline
        autoPlay
      />
    </div>
  );
};

export default VideoStream;
